
import { Component, Vue } from 'vue-property-decorator'
import { SoilFertilityDetail } from '../../types/soilFertility.d'
@Component({
  name: 'DetailList'
})
export default class DetailList extends Vue {
  private info = {
    deviceNumber: '',
    deviceStatus: ''
  }

  private loading = false
  private dataList: Array<SoilFertilityDetail> = []
  page = 1
  size = 10
  total = 0

  get projectId () {
    return this.$route.params.projectId as string
  }

  created () {
    this.getData()
  }

  // 获取列表数据
  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<SoilFertilityDetail> }>(this.$apis.soilFertility.selectSoilFertilityPage, {
      projectId: this.projectId,
      page: this.page,
      size: this.size,
      deviceNumber: this.info.deviceNumber,
      deviceStatus: this.info.deviceStatus
    }).then(res => {
      this.total = res.total || 0
      this.dataList = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  toHistory (deviceId: string) {
    this.$router.push({ name: 'soilFertilityListHistory', params: { projectId: this.projectId, deviceId: deviceId } })
  }
}
